import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import styled from "@emotion/styled"

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5em;
`
const Spinner = () => {
  return (
    <SpinnerContainer>
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </SpinnerContainer>
  )
}

export default Spinner
