/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import type { GatsbyBrowser } from "gatsby"

export const onServiceWorkerUpdateReady: GatsbyBrowser["onServiceWorkerUpdateReady"] =
  () => {
    document
      .getElementById("___gatsby")!
      .setAttribute("data-update-available", "true")
  }

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  if (process.env.NODE_ENV === "development") {
    return
  } else {
    navigator.serviceWorker
      .register("/sw.js")
      .then((reg) => {
        reg.update()
      })
      .catch(() => console.error("Error trying to register service worker"))
  }
}
