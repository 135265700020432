import { createTheme } from "@mui/material/styles"

declare module "@mui/material/styles" {
  interface PaletteOptions {
    danger: string
    holy: string
    dark: string
    ice: string
    fire: string
    nature: string
    all: string
  }
}

const theme = createTheme({
  typography: {
    h3: {
      fontFamily: "Oswald",
      lineHeight: "1.1",
    },
  },
  palette: {
    danger: "#A00000",
    primary: {
      main: "#203c58",
      light: "#4d6685",
      dark: "#00162f",
    },
    secondary: {
      main: "#be3c3c",
      light: "#f66d67",
      dark: "#870016",
    },
    holy: "#bbab1d",
    dark: "purple",
    ice: "#196897",
    fire: "#be3c3c",
    nature: "green",
    all: "inherit",
  },
})

export default theme
