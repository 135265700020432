import { useEffect, useState } from "react"
import Snackbar from "@mui/material/Snackbar"
import SnackbarContent from "@mui/material/SnackbarContent"
import Button from "@mui/material/Button"

export const Notification = () => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

  const handleRefresh = () => {
    window.location.reload()
  }
  const action = (
    <Button
      sx={{ backgroundColor: "white", color: "#203c58" }}
      variant="contained"
      size="small"
      onClick={handleRefresh}
    >
      Refresh
    </Button>
  )
  useEffect(() => {
    const interval = setInterval(() => {
      const isUpdateAvailable =
        document.getElementById("___gatsby").dataset.updateAvailable === "true"

      if (isUpdateAvailable) {
        setIsUpdateAvailable(true)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return isUpdateAvailable ? (
    <Snackbar open={true}>
      <SnackbarContent
        message="Good news! There is a new version of this site available. Refresh to
          get the latest updates."
        action={action}
        sx={{ backgroundColor: "#203c58", color: "#ffffff" }}
      />
    </Snackbar>
  ) : null
}

export default Notification
