import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : {}
}

const WrappedLink = (props) => (
  <Link to={props.to} getProps={isActive} {...props}>
    <ListItemButton
      onClick={props.onClick ? props.onClick : null}
      key={props.id}
      sx={props.sx ? props.sx : {}}
    >
      {props.icon && (
        <ListItemIcon>
          <FontAwesomeIcon icon={props.icon} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={props.text}
        primaryTypographyProps={props.style ? props.style : null}
      />
    </ListItemButton>
  </Link>
)

export default WrappedLink
