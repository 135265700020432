import ListSubheader from "@mui/material/ListSubheader"
import {
  faStar,
  faSparkles,
  faHammerWar,
  faComment,
} from "@fortawesome/pro-solid-svg-icons"
import {
  faCalculator,
  faPlus,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faDragon,
  faUsers,
  faChartColumn,
  faArrowRightArrowLeft,
  faBolt,
  faPencil,
  faShield,
  faSwords,
  faUserPlus,
  faDatabase,
  faCalendarDays,
  faHelmetBattle,
  faMagnifyingGlass,
} from "@fortawesome/pro-duotone-svg-icons"
import styled from "@emotion/styled"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import { Links, WrappedLink } from "./Links"
import { styled as MuiStyled } from "@mui/material/styles"

const CustomNav = MuiStyled(List)<{ component?: React.ElementType }>({
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
})

const DrawerWrapper = styled.div`
  .active {
    background-color: rgba(32, 60, 88, 0.08);
  }
  a {
    display: flex;
    align-items: center;
    flex-grow: 1;
    text-decoration: none;
    color: unset;
    :visited {
      color: unset;
    }
  }
  padding-bottom: 15px;
`

interface NavDrawerPropsT {
  location: {
    pathname: string
  }
  onClick?: () => void
}

const NavDrawer = ({ location, onClick }: NavDrawerPropsT) => {
  const showAllianceButtons = RegExp("/stats").test(location.pathname)
  const showClassQuests = RegExp("/guides/class").test(location.pathname)
  const showRareQuests = RegExp("/guides/rare").test(location.pathname)
  const showChallengeEvents = RegExp("/guides/challenge").test(
    location.pathname
  )
  const showSeasonalEvents = RegExp("/guides/seasonal").test(location.pathname)
  return (
    <DrawerWrapper>
      <CustomNav>
        <ListSubheader component="div" id="alliance-subheader">
          Alliance
        </ListSubheader>
        <WrappedLink
          to="/members"
          id="members"
          icon={faUsers}
          text="Members"
          onClick={onClick}
        />
        <WrappedLink
          to="/stats"
          id="stats"
          icon={faChartColumn}
          text="Stats"
          onClick={onClick}
        />
        {showAllianceButtons ? (
          <List component="div" disablePadding>
            <WrappedLink
              to="/stats/titans"
              id="titans"
              icon={faDragon}
              text="Titan Log"
              onClick={onClick}
              sx={{ pl: 5 }}
            />
            <WrappedLink
              to="/stats/wars"
              id="wars"
              icon={faHammerWar}
              text="War Log"
              onClick={onClick}
              sx={{ pl: 5 }}
            />
          </List>
        ) : null}
        <WrappedLink
          to="/join"
          id="join"
          icon={faUserPlus}
          text="Join"
          onClick={onClick}
        />
      </CustomNav>
      <Divider />
      <CustomNav>
        <ListSubheader component="div" id="tools-subheader">
          Tools
        </ListSubheader>
        <WrappedLink
          to="/tools/heroes"
          id="hero-lookup"
          icon={faMagnifyingGlass}
          text="Hero Lookup"
          onClick={onClick}
        />
        <WrappedLink
          to="/tools/compare"
          id="compare"
          icon={faArrowRightArrowLeft}
          text="Compare"
          onClick={onClick}
        />
        <WrappedLink
          to="/tools/talent-simulator"
          id="talent-simulator"
          icon={faBolt}
          text="Talent Simulator"
          onClick={onClick}
        />
        <WrappedLink
          to="/tools/talent-node-calculator"
          id="talent-node-calculator"
          icon={faCalculator}
          text="Talent Node Calculator"
          onClick={onClick}
        />
        <WrappedLink
          to="/tools/database"
          id="database"
          icon={faDatabase}
          text="Hero Database"
          onClick={onClick}
        />
        <WrappedLink
          to="/tools/recent-heroes"
          id="recent"
          icon={faHelmetBattle}
          text="Recent Heroes"
          onClick={onClick}
        />
      </CustomNav>
      <Divider />
      <CustomNav>
        <ListSubheader component="div" id="resources-subheader">
          Other
        </ListSubheader>
        <WrappedLink
          to="/add-hero"
          id="add-hero"
          icon={faPlus}
          text="Add a Hero"
          onClick={onClick}
        />
        <WrappedLink
          to="/edit-hero"
          id="edit-hero"
          icon={faPencil}
          text="Edit a Hero"
          onClick={onClick}
        />
        <WrappedLink
          to="/feedback"
          id="feedback"
          icon={faComment}
          text="Send Feedback"
          onClick={onClick}
        />
        <WrappedLink
          to="/about"
          id="about"
          icon={faCircleInfo}
          text="About"
          onClick={onClick}
        />
      </CustomNav>
    </DrawerWrapper>
  )
}

export default NavDrawer
