import { useState, Suspense } from "react"
import { Script, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-light-svg-icons"
import { faPlanetRinged } from "@fortawesome/pro-duotone-svg-icons"
import { ThemeProvider } from "@mui/material/styles"
import { ContextProviderComponent } from "./Context"
import styled from "@emotion/styled"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import CssBaseline from "@mui/material/CssBaseline"
import NavDrawer from "./NavDrawer"
import GlobalStyles from "../styles/global"
import theme from "../styles/theme"
import Notification from "./Notification"
import Spinner from "./Spinner"

const AppBarLink = styled.div`
  display: inline-flex;
  a {
    text-decoration: none;
    color: unset;
    :visited {
      color: unset;
    }
  }
`
const drawerWidth = 240

interface LayoutPropTypes {
  children: React.ReactNode
  window: any
  location: {
    pathname: string
  }
}

function Layout({ window, location, children }: LayoutPropTypes) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      <Script
        defer={true}
        src="https://cdn.headwayapp.co/widget.js"
        onLoad={() => setLoaded(true)}
      ></Script>
      {loaded && (
        <Script defer={true} id="changelog">
          {`var config = {
        selector: ".changelog",
        account:  "Yxbk57",
        translations: {
        title: "Latest Updates",
        readMore: "Read more",
        labels: {
          "new": "New",
          "improvement": "Updates",
          "fix": "Fixes"
        },
        footer: "Read more 👉"
        }
      }
      Headway.init(config)`}
        </Script>
      )}
      <CssBaseline />
      <ContextProviderComponent>
        <ThemeProvider theme={theme}>
          <GlobalStyles>
            <Notification />
            <Box>
              <AppBar
                color="primary"
                position="fixed"
                sx={{
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </IconButton>
                  <Typography variant="h6" noWrap component="div">
                    <AppBarLink>
                      <Link to="/">
                        <FontAwesomeIcon
                          icon={faPlanetRinged}
                          style={{ fontSize: "1em", marginRight: "5px" }}
                        />
                        Legends of Space
                      </Link>
                      <span className="changelog"></span>
                    </AppBarLink>
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="navigation"
              >
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  <Toolbar />
                  <NavDrawer onClick={handleDrawerToggle} location={location} />
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                  open
                >
                  <Toolbar />
                  <NavDrawer location={location} />
                </Drawer>
              </Box>
              <Box component="main" sx={{ marginLeft: { sm: "240px" } }}>
                <Toolbar />
                <Grid container>
                  <Grid item xs={12}>
                    <Suspense fallback={<Spinner />}>{children}</Suspense>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </GlobalStyles>
        </ThemeProvider>
      </ContextProviderComponent>
    </>
  )
}

export default Layout
