import { createContext, Component } from "react"
import axios from "axios"
import { AllianceData } from "../types"

const defaultContextValue = {
  data: {
    members: undefined,
    memberSnapshot: undefined,
    titans: undefined,
    wars: undefined,
    playerStats: undefined,
    stats: undefined,
  },
  set: () => null,
  getData: () => null,
  hasError: false,
}

interface ContextData {
  state: {
    data: AllianceData
  }
  data?: AllianceData
  set?: () => void
  getData?: () => void
  hasError?: boolean
}

interface ContextPropT {
  children: React.ReactNode
}

const { Provider, Consumer } = createContext<Partial<ContextData>>({})

class ContextProviderComponent extends Component<ContextPropT> {
  state = {
    ...defaultContextValue,
  }
  source = axios.CancelToken.source()

  componentDidMount() {
    this.getData()
  }

  // componentwillUnMount() {
  //   this.getData.cleanup()
  // }

  getData = () => {
    axios
      .get("/api/data", {
        cancelToken: this.source.token,
      })
      .then((resp) => {
        if (resp.status === 200) {
          this.setState((state) => ({
            ...state,
            data: {
              members: resp.data.members.players,
              memberSnapshot: resp.data.memberSnapshot.players,
              titans: resp.data.logs.titan,
              wars: resp.data.logs.war,
              playerStats: {
                playerTitanStats: resp.data.charts.titan,
                playerWarStats: resp.data.charts.war,
              },
              stats: resp.data.stats,
            },
          }))
        }
      })
      .catch((err) => {
        console.error(err.message)
        this.setState({ hasError: true })
      })

    return function cleanup() {
      this.source.cancel("Cancelling in cleanup")
    }
  }

  setData = (newData: ContextData) => {
    this.setState((state) => ({
      ...state,
      ...newData,
    }))
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, ContextProviderComponent }
