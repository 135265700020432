import styled from "@emotion/styled"
import "typeface-roboto"
import "typeface-oswald"

const GlobalStyles = styled.div`
  @font-face {
    font-family: "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
    font-display: block;
  }
  small {
    font-size: 0.8rem;
    color: #666;
  }
  @media only screen and (min-width: 767px) {
  div.hideForDesktop {
    display: none;
  }
}
`

export default GlobalStyles
